import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import DistributeUnassignedBalanceCommand from '@/modules/flagship/investor-goal/distribute-unassigned-balance/application/commands/distribute-unassigned-balance-command';

// Domain
import {
  InvestorGoal,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/entities/distribute-unassigned-balance-entity';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type ArrayLinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  alt: string;
  assigned_amount_field: string;
  icon_path: string;
}

export default class ModalDistributeUnassignedAmountViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.DISTRIBUTE_UNASSIGNED_BALANCE_COMMAND)
  private readonly distribute_unassigned_balance_command!: DistributeUnassignedBalanceCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.kuspit-dashboard.goals.modal-distribute-unassigned-amount';

  linked_goals: Array<ArrayLinkedGoals> = [];

  deposit_amount = 0;

  step = 50;

  is_disabled = false;

  index_unassigned_amount = 0;

  is_modal_open = true;

  is_loading = true;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  private active_goals: Array<SearchByCustomerEntity> = [];

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    this.setUnassignedAmount();
    await this.loadUnassignedAmount();
    this.is_loading = false;
  }

  get deposit_amount_formatted() {
    return currencyFormat(this.deposit_amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '21px');

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      Object.entries(investment_products).forEach((item: any) => {
        if (item[1].name === 'sowos_pocket') {
          this.search_by_customer_dto.associated_product_id = item[1].id;
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      this.active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      Object.entries(this.active_goals).forEach((goal: any) => {
        this.linked_goals.push({
          investor_goal_id: goal[1].investor_goal_id,
          // eslint-disable-next-line max-len
          name: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.goal_name : goal[1].investment_goal_type.name,
          // eslint-disable-next-line max-len
          label: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.goal_name : goal[1].investment_goal_type.label,
          icon: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.custom_goal_type.icon_name : 'icon-retirement.svg',
          assigned_amount: 0,
          alt: (goal[1].custom_investor_goal) ? 'alts.custom' : 'alts.emergency',
          assigned_amount_field: '0.00',
          icon_path: getGoalIconPath(
            goal[1].investor_goal_id,
            goal[1].custom_investor_goal?.custom_goal_type?.icon_name || 'icon-retirement.svg',
            goal[1].custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  loadUnassignedAmount = async () => {
    try {
      const custom_investor_goal_balance = await this.get_customer_investor_goal_balance_query
        .execute(
          // eslint-disable-next-line max-len
          { investment_product_id: this.search_by_customer_dto.associated_product_id },
        );
      if (custom_investor_goal_balance.unassigned_balance) {
        this.setDepositAmount(custom_investor_goal_balance.unassigned_balance.toString());
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_unassigned_amount'));
    }
  }

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: '0.00',
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
  }

  setDepositAmount = (amount: string) => {
    const parsed_number_without_characters = parseFloat(amount.replaceAll(/[^0-9.]/g, ''));
    const index = this.linked_goals.findIndex((item) => item.name === 'unassigned');
    this.deposit_amount = parsed_number_without_characters;
    this.linked_goals[index].assigned_amount = parsed_number_without_characters;
    this.index_unassigned_amount = index;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.deposit_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index]
        .assigned_amount.toFixed(2);
      this.validateTotalAmounts(false);
    }
  }

  decrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount -= this.step;
    if (this.linked_goals[index].assigned_amount > 0 && current_amount >= 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index]
        .assigned_amount.toFixed(2);
      this.validateTotalAmounts(true);
    }
  }

  addUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals < this.deposit_amount) {
      const res = this.deposit_amount - total_amount_goals;
      if (res >= 0) {
        this.linked_goals[this.index_unassigned_amount].assigned_amount = res;
      }
    }
  }

  subtractUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount].assigned_amount -= this.step;
    }
  }

  validateTotalAmounts = (is_add: boolean, amount_was_edited_field = false) => {
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, index) => ((index !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    let total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (!amount_was_edited_field) {
      if (is_add) {
        this.addUnassignedAmount(total_amount_goals);
      } else {
        this.subtractUnassignedAmount(total_amount_goals);
      }
    }
    total_amount_goals += this.linked_goals[this.index_unassigned_amount].assigned_amount;
    this.is_disabled = total_amount_goals !== this.deposit_amount;
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')))
      : 0;
    const is_add = (parsed_assigned_amount_field > this.linked_goals[index].assigned_amount);
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.deposit_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = parsed_assigned_amount_field.toFixed(2);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
    } else {
      this.linked_goals[index].assigned_amount_field = '0';
      this.linked_goals[index].assigned_amount = 0;
    }
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, position) => ((position !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    const total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_amount_goals;
    }
    this.validateTotalAmounts(!is_add, true);
  }

  saveDistribution = async () => {
    try {
      const investor_goals: Array<InvestorGoal> = [];
      let unassigned_balance = 0;
      let total_distributed_balance = 0;
      this.linked_goals.forEach((goal) => {
        if (goal.investor_goal_id && goal.assigned_amount > 0) {
          investor_goals.push(
            {
              investor_goal_id: goal.investor_goal_id,
              amount_to_distribute: goal.assigned_amount.toString(),
            },
          );
          total_distributed_balance += goal.assigned_amount;
        } else {
          unassigned_balance = goal.assigned_amount;
        }
      });
      await this.distribute_unassigned_balance_command
        .execute({
          investment_product_id: this.search_by_customer_dto.associated_product_id,
          investor_goals,
          remaining_unassigned_balance: unassigned_balance.toFixed(6),
          total_distributed_balance: total_distributed_balance.toString(),
        });
      const linked_goals_pocket_event = new CustomEvent('pocket.linked.goal', { detail: { } });
      window.dispatchEvent(linked_goals_pocket_event);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.save_distribution'));
    }
  }
}
