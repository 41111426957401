























































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import ModalDistributeUnassignedAmountViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/goals/modal-distribute-unassigned-amount-view-model';

@Component({
  name: 'ModalDistributeUnassignedAmount',
})
export default class ModalDistributeUnassignedAmount extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  modal_distribute_unassigned_amount_view_model = Vue.observable(
    new ModalDistributeUnassignedAmountViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  endProcess() {
    this.modal_distribute_unassigned_amount_view_model.saveDistribution();
    this.synced_is_open = false;
  }

  mounted() {
    this.modal_distribute_unassigned_amount_view_model.initialize();
  }
}
